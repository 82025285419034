// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer * 0.5) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer * 0.5);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer * 0.5);
  }
  .alignright {
    float: right;
    margin-left: ($spacer * 0.5);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
