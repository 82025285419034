@font-face {
  font-family: "ToyotaType-Light";
  src: url(../fonts/ToyotaType-Light.ttf) format("truetype"), url(../fonts/ToyotaType-Light.woff) format("woff"), url(../fonts/ToyotaType-Light.woff2) format("woff2"), url(../fonts/ToyotaType-Light.eot?) format("eot");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "ToyotaType-Book";
  src: url(../fonts/ToyotaType-Book.ttf) format("truetype"), url(../fonts/ToyotaType-Book.woff) format("woff"), url(../fonts/ToyotaType-Book.woff2) format("woff2"), url(../fonts/ToyotaType-Book.eot?) format("eot");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "ToyotaType-Regular";
  src: url(../fonts/ToyotaType-Regular.ttf) format("truetype"), url(../fonts/ToyotaType-Regular.woff) format("woff"), url(../fonts/ToyotaType-Regular.woff2) format("woff2"), url(../fonts/ToyotaType-Regular.eot?) format("eot");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "ToyotaType-Semibold";
  src: url(../fonts/ToyotaType-Semibold.ttf) format("truetype"), url(../fonts/ToyotaType-Semibold.woff) format("woff"), url(../fonts/ToyotaType-Semibold.woff2) format("woff2"), url(../fonts/ToyotaType-Semibold.eot?) format("eot");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "ToyotaType-Bold";
  src: url(../fonts/ToyotaType-Bold.ttf) format("truetype"), url(../fonts/ToyotaType-Bold.woff) format("woff"), url(../fonts/ToyotaType-Bold.woff2) format("woff2"), url(../fonts/ToyotaType-Bold.eot?) format("eot");
  font-style: normal;
  font-weight: 400;
}
body {
  font-family: "ToyotaType-Regular";
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.4;
}
body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.section-spacing {
  padding: 65px 0;
  &.empty_section {
    padding: 30px 0 0;
  }
}
.vertical-align {
  @include vertical-align($position: absolute);
}
a {
  color: $red;
  &:hover {
    color: $red;
    text-decoration: none;
  }
}
:focus {
  outline: none;
}
p {
  margin: 0 0 30px;
}
p b {
  font-family: "ToyotaType-Semibold";
  font-weight: normal;
}
b, strong {
  font-family: "ToyotaType-Semibold";
  font-weight: normal;
}
.btn.focus, .btn:focus, .comment-form input.focus[type=submit], .comment-form input[type=submit]:focus, .search-form .focus.search-submit, .search-form .search-submit:focus {
    outline: 0;
    box-shadow:none;
}

.img-fluid, .img-thumbnail, .wp-caption>img {
 		height: inherit;
    width: 100%;
}

figure {
  margin: 0;
}
h1, h2, h3 {
  font-weight: 200;
  font-family: "ToyotaType-Light";
}
.thin_heading {
  font-family: "ToyotaType-Light";
}
.semi_bold_heading {
  font-family: "ToyotaType-Semibold";
}
.m-bottom {
  margin-bottom: 60px;
}
.crumbs {
  padding: 10px 0;
  background: #f5f5f5;
  border-bottom: 1px solid #eee;
  margin: 0 0 30px;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-right: 0px;
      &:after {
        content: '/';
        display: inline-block;
        margin: 0 10px;
        color: #a5a5a5;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #a5a5a5;
        &:hover {
          color: #a5a5a5;
        }
      }
    }
  }
}
.page-title {
  margin: 45px 0 45px;
  
  h1 {
    margin: 0;
    font-size: 48px;
    line-height: 1;
  }
}
.single-post {
  .page-title h1 {
    max-width: 760px;
  }
}
.modal-backdrop.show {
  opacity: .8;
}


.modal {
  .close {
    position: absolute;
    right: 0;
    top: -40px;
    color: #fff;
  }
  .close:focus, .close:hover {
    color: #fff;
  }
  .close {
    float: right;
    font-size: 34px;
    font-weight: 100;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .video_wrapper {
    width: 850px;
    position: relative;
    padding-bottom: 56.7%!important;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
#sign-up-modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 730px;
      height: 100vh;
      display: flex;
      align-items: center;
      top: 0px;
			margin-right: 30px;
    }
  }
  .modal-content {
    background-color: hsl(0, 0%, 31%);
    //overflow: hidden;
  }
	.modal-body {
		overflow: hidden;
	}
  form {
    padding: 20px 20px 20px 0;
  }
  .entry {
    position: relative;
    background: $red;
    width: 85%;
    height: 276px;
    padding: 20px;
    color: #fff;
    .btn-block {
      text-align: left;
      font-size: 14px;
      position: relative;
      z-index: 10;
      padding: 0;
      margin: 10px 0 0;
    }



    h3 {
      font-family: "ToyotaType-Semibold";
    }
    .checkbox {
      position: relative;
      z-index: 10;
    }
  }
  .clipping_mask {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    &:after {
      top: 0;
      right: -100px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0;
      margin-left: 0;
      border: 50px solid #fff;
      border-color: transparent transparent #d61820 #d61820;
      transform-origin: 0 0;
      box-shadow: -1px 1px 0 0 rgba(0, 0, 0, .17);
      -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .17);
      z-index: 0;
      border-top: 138px solid transparent;
      border-bottom: 138px solid transparent;
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 850px;
  }
}
.modal.fade .modal-dialog {
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  top: 70px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.modal.fade.show .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 1;
}
.modal-content {
  width: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
.modal-body {
  position: relative;
  padding: 0;
}
@media (max-width: 992px) {
  .page-title h1 {
    font-size: 36px;
    line-height: 1;
  }
  .video_wrapper {
    max-width: 850px;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 1.4;
  }
  .h2, h2 {
    font-size: 22px;
  }
  .page-title h1 {
    font-size: 28px;
    line-height: 1;
  }

  .section-spacing {
      padding: 30px 0;
  }
	
	#sign-up-modal { 
		.entry {
   width: 100%;
			height: inherit;
			h3 {
				font-size: 18px;
			}
    
}
	form {
    padding: 20px 20px 30px 20px;
}	
		
	}

}
@media (max-width: 668px) {

.page-title {
    margin: 25px 0 25px;
}
	
}
