//single styles
.entry-title {
  display: block;
  font-size: 28px;
  line-height: 1;
  margin: 0 0 15px;
}
.mag-title {
  font-family: "ToyotaType-Bold";
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 20px;
  span {
    display: block;
  }
}
.issue-info {
  font-family: "ToyotaType-Bold";
  display: block;
  margin: 5px 0 0;
}
.single {
  .img-holder {
    position: relative;
  }
  .play-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: #d6d6d6;
    padding: 0;
    .audiojsZ {
      width: 50px;
      height: 50px;
      .play-pauseZ {
        width: 50px;
        height: 50px;
        padding: 0;
        margin: 0;
      }
      .playZ {
        background: none;
        text-align: center;
        width: 50px;
        height: 50px;
        i {
          font-size: 28px;
          line-height: 45px;
          text-align: center;
        }
      }
      p {
				text-align: center;
        width: 96%;
        height: 100%;
      }
      .pauseZ {
        background: none;
        &:before {
          content: "\f478";
          font-size: 23px;
          display: inline-block;
          font-family: "Ionicons";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-rendering: auto;
          line-height: 50px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
}
.indent-margin {
  margin-left: 120px;
}
.single-collision_pros_pub .content {
  ul {
    margin-left: 20px;
    li {
      list-style: none;
      &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #d61820;
        margin: 0 7px 2px 0;
      }
    }
  }
  .wp-block-gallery {
    margin-left: 0px;
    padding: 0 0 20px 0px;
    li {
      &:before {
        display: none;
      }
    }
  }
}
.single-post-entry {
  p {
    font-family: "ToyotaType-Light";
  }
  ul, ol {
    margin-left: 20px;
    li {
      font-family: "ToyotaType-Light";
    }
  }
  ul {
    li {
      position: relative;
      padding-left: 15px;
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1.1;
    font-family: "ToyotaType-Bold";
    color: $drk;
    margin: 0 0 5px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 20px;
    line-height: 1.1;
    font-family: "ToyotaType-Bold";
    color: $red;
    margin: 0 0 5px;
    text-transform: uppercase;
  }
}
.related_block {
  margin: 45px 0 30px;
  a {
    color: $drk;
    @include quick_easing;
    &:hover {
      color: $red;
      .readmore {
        right: -5px;
      }
    }
  }
  h4 {
    font-family: "ToyotaType-Bold";
    font-size: 16px;
    position: relative;
    padding-left: 20px;
    span {
      display: inline-block;
      width: 85%;
    }
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $red;
      margin: 0 7px 2px 0;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
  .block {
    position: relative;
    padding-right: 40px;
    .readmore {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      line-height: 28px;
      border: 1px solid $red;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      @include quick_easing;
      i {
        color: $red;
      }
    }
    p {
      padding-left: 19px;
    }
  }
}
.single .entry-content, .single .entry-content {
  .btn-holder {
    ul {
      margin: 15px 0 0;
      padding: 0;
      .btn.btn-primary {
        min-width: 230px;
        padding: 10px 20px;
      }
    }
  }
}
.wp-block-pullquote {
  p em {
    position: relative;
    &:before {
      content: "\201C";
      position: absolute;
      top: -20px;
      left: -.15em;
      color: rgba(229, 107, 114, 0.40);
      font-size: 6em;
      line-height: 1;
      height: 60px;
      z-index: -1;
      font-family: georgia;
    }
    &:after {
      content: '\201D';
      position: absolute;
      bottom: -30px;
      right: 0px;
      color: rgba(229, 107, 114, 0.40);
      font-size: 6em;
      line-height: 1;
      height: 60px;
      z-index: -1;
      font-family: georgia;
    }
  }
}
//page styles
.page-styles {
  h1, h2 {
    font-size: 28px;
    line-height: 1.3;
    margin: 0 0 20px;
  }
  h3 {
    font-size: 22px;
    line-height: 1.2;
    margin: 0 0 20px;
  }
  p {
    margin: 0 0 30px;
  }
  ul {
    margin: 0;
    padding: 0 0 20px 20px;
  }
  ol {
    margin: 0;
    padding: 0 0 20px 20px;
    li {
      display: block;
      margin-bottom: 10px;
    }
  }
}
.single {
  iframe {
    width: 100%;
  }
}
.entry-content {
  .btn-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
    h4 {
      margin: 0 0 20px;
      font-size: 16px;
      line-height: 1.2;
    }
  }
}
.single-products {
  .entry-content {
    .entry {
      h1, h2 {
        font-size: 28px;
        line-height: 1.3;
        margin: 0 0 20px;
      }
      h3 {
        font-size: 22px;
        line-height: 1.2;
        margin: 0 0 20px;
      }
      ul {
        margin: 0px 0 0;
        padding: 0 0 20px 20px;
      }
    }
    ol {
      margin: 0px 0 0;
      padding: 0 0 20px 20px;
      li {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
.single {
  .entry-content {
    ol, ul {
      margin: 10px 0 0;
      padding: 0 0 20px 20px;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .social-items {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      a {
        width: 30px;
        height: 30px;
        display: inline-block;
        border: 1px solid $red;
        text-align: center;
        line-height: 30px;
        @include quick_easing();
        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
  }
}
.post-article {
  .entry {
    i {
      position: relative;
      top: 1px;
    }
  }
}
.cat-titles {
  color: $red;
  font-size: 20px;
  position: relative;
  display: inline-block;
  top: 2px;
  margin-right: 5px;
}
@media (min-width: 990px) {
  .affix {
    position: fixed;
    top: 20px;
    margin-top: 0px;
    padding-right: 30px;
  }
}
.affix-top {
  position: static;
}
.affix-bottom {
  position: relative;
}
.sidebar {
  will-change: min-height;
}
.sidebar__inner {
  transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}
.sidebar {
  max-width: 570px;
  max-width: 570px;
  height: 100% !important;
  border-left: 1px solid #d6d6d6;
  .sidebar__inner {
    padding-left: 30px;
  }
  .entry {
    padding: 30px 0;
    h3 {
      font-family: "ToyotaType-Semibold";
      font-size: 16px;
      margin: 0 0 15px;
    }
    .underline {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 0px;
        padding-left: 0;
        position: relative;
        padding-left: 15px;
        &:before {
          display: none;
        }
        a {
          color: $drk;
          padding: 6px 0;
          display: inline-block;
        }
        i {
          position: absolute;
          top: 14px;
          left: 0;
          display: inline-block;
          width: 8px;
          height: 8px;
          background: $red;
          margin: 0 7px 2px 0;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    ul {
      padding: 0;
      margin: 0 0 0 0;
      li {
        list-style: none;
        margin-bottom: 0px;
        padding-left: 15px;
        position: relative;
        a {
          color: $drk;
          padding: 2px 0;
          text-transform: capitalize;
          display: inline-block;
          @include quick_easing;
          &:hover {
            color: $red;
          }
        }
        &:before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: $drk;
          margin: 0 7px 2px 0;
          position: absolute;
          top: 11px;
          left: 0;
        }
        .ion-ios-box {
          color: $red;
          margin-right: 3px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .indent-margin {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .sidebar {
    border-left: none;
    padding-left: 0px;
  }
  .sidebar .sidebar__inner {
    padding-left: 0px;
    position: relative !important;
    width: auto !important;
  }
}
@media (max-width: 668px) {
  .single-products .entry-content .entry h1, .single-products .entry-content .entry h2 {
    font-size: 24px;
  }
  .single-post-entry h2 {
    font-size: 18px;
    line-height: 1.3;
    .block-quote-padding {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .entry-title {
    font-size: 22px;
  }
  .post-article {
    .img-holder {
      margin-bottom: 15px;
    }
  }
}