.footer {
		margin-top: 0px;
	  background: $lt_blue;
		padding: 60px 0 0;
		border-top: 1px solid #dcdee0;

  .sub_meta {
    padding: 45px 0 30px;
  }



  #mc_embed_signup div.mce_inline_error {
    margin: 5px 0;
    padding: 2px 0px;
    font-size: 13px;
    background-color: transparent;
    font-weight: normal;
    z-index: 1;
    color: red;
}

		.form-control{

    color: #363636;
    background-color: transparent;
    background-image: none;
    border: none;
    font-size:13px;
	  border-bottom: 1px solid #b9b9b9;
    border-radius: 0px;
    box-shadow: none;
    padding: 8px 0;


}
	.btn {
		float: right;
        font-size: 14px;
        @include easing();
        &:hover {
            //background: $dark-grey;
        }
	}

		.inner {
			h4 {
				color: #363636;
				font-size: 14px;
				line-height:1;
			  
			}
			ul {
				padding: 0;
				margin:0;
				li {
					list-style: none;
					a {
						color: #363636;
						font-size:13px;

					}
				}
			}

			.social {
				margin: 20px 0 0;
				 margin-left: -5px;

				a {
					background-color: $red;
					color:#fff;
					 width: 30px;
					height: 30px;
					line-height: 30px;
					text-align: center;
					border-radius:50%;
					display:block;

				}

			}



		}

}

@media (max-width: 1024px) {


.footer {
.inner {
    margin: 0 0 30px;
}

}


}
