@media print {
  /* All your print styles go here */
  input.btn {
    display: none !important;
  }
}

button:focus {

    outline: none;
}


.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: inherit;
}
.btn.btn-primary {
  color: #fff;
  background-color: #d61820;
  border-color: $red;
  border-radius: 0;
  padding: 10px 40px;
  font-family: "ToyotaType-Semibold";
  @include easing();
  &:hover {
    background: $red;
  }
}
.btn.btn-primary.fill {
  background: $red;
  &:hover {
    background: none;
    color: $red;
  }
}
.btn.btn-primary.fill.w-hover {
  &:hover {
    background: none;
    border-color: #fff;
    color: #fff;
  }
}
.btn.btn-primary.border-btn {
  color: $red;
  &:hover {
    background: none;
    color: $red;
  }
}
//audios
.audiojsZ audio {
  position: absolute;
  left: -1px;
}
.audiojsZ {
  width: 40px;
  height: 36px;
  /* background: red; */
  overflow: hidden;
  font-family: ToyotaType-Semibold;
  font-size: 12px;
  display: inline-block;

}
.audiojsZ .play-pauseZ {
  width: 40px;
  height: 40px;
  padding: 4px 6px;
  margin: 0px;
  float: left;
  overflow: hidden;

}
.audiojsZ p {
  display: none;
  width: 25px;
  height: 25px;
  margin: 0px;
  cursor: pointer;
}
.audiojsZ .playZ {
  display: block;
}
.audiojsZ .scrubberZ {
  position: relative;
  float: left;
  width: 280px;
  background: #fcc;
  height: 14px;
  margin: 10px;
  border-left: 0px;
  border-bottom: 0px;
  overflow: hidden;
}
.audiojsZ .progressZ {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 14px;
  width: 0px;
  background: #600;
  z-index: 1;
}
.audiojsZ .loadedZ {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 14px;
  width: 0px;
  background: #c00;
}
.audiojsZ .timeZ {
  float: left;
  height: 36px;
  line-height: 36px;
  margin: 0px 0px 0px 6px;
  padding: 0px 6px 0px 12px;
  border-left: 1px solid #c00;
  color: #600;

}
.audiojsZ .timeZ em {
  padding: 0px 2px 0px 0px;
  color: #fcc;
  font-style: normal;
}
.audiojsZ .timeZ strong {
  padding: 0px 0px 0px 2px;
  font-weight: normal;
}
.audiojsZ .error-messageZ {
  float: left;
  display: none;
  margin: 0px 10px;
  height: 36px;
  width: 400px;
  overflow: hidden;
  line-height: 36px;
  white-space: nowrap;
  color: #fff;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.audiojsZ .error-messageZ a {
  color: #eee;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #999;
  white-space: wrap;
}
.audiojsZ .playZ {
  background: url('../images/player-graphics.gif') -2px -1px no-repeat;
}
.audiojsZ .loadingZ {
  background: url('../images/player-graphics.gif') -2px -31px no-repeat;
}
.audiojsZ .errorZ {
  background: url('../images/player-graphics.gif') -2px -61px no-repeat;
}
.audiojsZ .pauseZ {
  background: url('../images/player-graphics.gif') -2px -91px no-repeat;
}
.playingZ .playZ, .playingZ .loadingZ, .playingZ .errorZ {
  display: none;
}
.playingZ .pauseZ {
  display: block;
}
.loadingZ .playZ, .loadingZ .pauseZ, .loadingZ .errorZ {
  display: none;
}
.loadingZ .loadingZ {
  display: block;
}
.errorZ .timeZ, .errorZ .playZ, .errorZ .pauseZ, .errorZ .scrubberZ, .errorZ .loadingZ {
  display: none;
}
.errorZ .errorZ {
  display: block;
}
.errorZ .play-pauseZ p {
  cursor: auto;
}
.errorZ .error-messageZ {
  display: block;
}

.pull-right {
  padding-right: 40px;
}

.overlay-articles a.icon-arrow-right {
  color: $red;
  position: relative;
  &:hover {
    color: $red;
  }
}

.icon-arrow-right:before {
   content: "\f3d6";
    display: inline-block;
    font-family: Ionicons;
 }


.btn-a:hover:before {
   right: -13px;
   opacity: 1;
}

.btn-a:before {
    right: 0px;
    opacity: 0;
    top: 0;
}

.btn-arrow:before {
	position: absolute;
	height: 100%;
	font-size: 30px;
  line-height: 40px;
	color: $red;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition: all .3s;
}
