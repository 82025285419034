
.forms {
  .form {
    .focused {
      outline: -webkit-focus-ring-color auto 1px;
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: $spacer * 0.25; // 8px;
      border-radius: 8px;
      overflow: hidden;
      background-color: var(--wp--preset--color--white);
    }

    .input {
      flex: 1 1 0%;
      padding-right: $spacer * 0.5; // 16px;
      padding-top: $spacer * 0.375; // 12px;
      padding-bottom: $spacer * 0.375; // 12px;
      color: black;
      font-size: 0.875rem; // 14px;
      line-height: 0.875rem;
      border: none;
      background-color: var(--wp--preset--color--white);
    }

    &-searchbar {
      .input-wrapper {
        .search-icon-wrapper {
          padding-left: $spacer * 0.25; // 16px;
          padding-right: $spacer * 0.25; // 16px;
          padding-top: $spacer * 0.375; // 12px;
          padding-bottom: $spacer * 0.375; // 12px;
          display: flex;
          align-items: center;
        }

        .search-input:focus-visible {
          outline: none;
        }

        .search-button {
          padding-left: $spacer * 0.75; //  24px;
          padding-right: $spacer * 0.75; // 24px;
          padding-top: 9px;
          padding-bottom: 9px;
          background: var(--wp--preset--color--primary-red);
          color: var(--wp--preset--color--white);
          font-size: 0.875rem; // 14px;
          font-family: var(--wp--preset--font-family--toyota-type-semibold);
          line-height: 1.375rem;
          border: none;
        }
      }
    }

    &-suggestion {
      .input-wrapper {
        background-color: transparent;
      }

      .input {
        border-radius: 8px;
        padding-left: $spacer * 0.5; // 16px;
      }

      .circle-wrapper {
        margin-right: $spacer * 0.375; // 12px;
      }
    }
  }

  .form-box-content {
    .error-container {
      border: 1px solid red;
      margin-bottom: 1.5rem;
      padding: 0.5rem;
      background: #ffe7e7;
      border-radius: 8px;
    }

    .error-message {
      display: inline;
      margin-left: .35rem;
      color: red;
      font-weight: 500;
    }


    .success-container {
      border: 1px solid green;
      margin-bottom: 1.5rem;
      padding: 0.5rem;
      background: rgb(209, 245, 209);
      border-radius: 8px;
    }

    .success-message {
      display: inline;
      margin-left: .35rem;
      color: green;
      font-weight: 500;
    }
  }

  .forms-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: $spacer * 2;

    @media screen and (max-width: 768px) {
      flex-direction: row;
    }

    * {
      box-sizing: border-box;
    }

    div {
      flex-grow: 1;
      flex-basis: 50%;
    }

    .invalid-feedback {
      display: none;
      padding-left: 0.7rem;
      position: relative;
      left: 0.75rem;

      &::before {
        content: '!';
        display: flex;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: var(--wp--preset--color--toyota-red);
        color: var(--wp--preset--color--white);
        font-family: var(--wp--preset--font-family--toyota-type-semibold);
        font-size: 0.6rem;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0.25rem;
        left: -0.7rem;
      }
    }

    .form-box-sign-in {
      display: none;

      @media screen and (max-width: 768px) {
        display: flex;
      }
    }

    .form-box {
      display: flex;
      justify-content: center;
      align-items: center;

      &-content {

        max-width: 67%;

        &-logo {

          margin-top: 0;
          margin-bottom: 2rem;

        }

        &-intro {

          h2 {
            font-size: 2.5rem;
            font-family: var(--wp--preset--font-family--toyota-type-semibold);
            font-weight: normal;
            line-height: 3rem;
            letter-spacing: -0.0125rem;
            margin-top: 0;
            margin-bottom: 1.5rem;
          }

          p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.75rem;

            &:last-of-type {
              margin-bottom: 1.5rem;
            }
          }

        }

        &-form {

          .form-field {
            border: 1px solid lightgray;
            border-radius: 0.5rem;
            padding: 0.5rem 0.75rem;
            width: 100%;
            height: 3.35rem;
            position: relative;

            &:focus-within {
              border: 1px solid var(--wp--preset--color--black);
            }

            &-filled {
              border: 1px solid var(--wp--preset--color--black);
            }

            &-invalid {
              border: 1px solid var(--wp--preset--color--toyota-red);
              background: #fef3f4;
            }

            &.invalid {
              border: 1px solid var(--wp--preset--color--toyota-red);
              background: #fef3f4;
            }

            &-input,
            &-select {
              border: 0;
              outline: none;
              font-size: 0.875rem;
              line-height: 0.875rem;
              position: absolute;
              padding: 27px 0 8px 0;
              top: 0;
              line-height: 1rem;
              background: transparent;
              z-index: 1111;
              width: 90%;

              
              &::placeholder {
                color: transparent;
              }

              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
              }

              &:focus,
              &:focus-visible,
              &:valid {
                border: 0;
                outline: none;
              }


            }

            &-input.password-input {
              width: 70%;
            }

            &-label {
              display: block;
              position: absolute;
              color: var(--wp--preset--color--medium-gray);
              font-size: 0.875rem;
              line-height: 0.875rem;
              margin: 0;
              padding: 0;
              top: 1.18rem;
              left: 0.7rem;
              transition: all 0.2s ease;
            }

            &.form-field-filled {
              border: 1px solid var(--wp--preset--color--black);

              .form-field-label {
                font-size: 0.625rem;
                top: 0.44rem;
              }
            }

            &.form-field-invalid {
              border: 1px solid var(--wp--preset--color--toyota-red);
              background: #fef3f4;

              .form-field-label {
                font-size: 0.625rem;
                top: 0.44rem;
              }
            }

            &-toggle-button {
              position: absolute;
              border: none;
              outline: none;
              background: none;
              margin: 0;
              padding: 0;
              top: 1.18rem;
              right: 0.7rem;
            }
          }

          .form-field-select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background-repeat: no-repeat;
            background-position-x: 98%;
            background-position-y: 0;
            width: 90%;

            &::-ms-expand {
              display: none;
            }
          }

          .select-dd-arrow {
            position: absolute;
            top: 1.4rem;
            right: 0.8rem;
            width: 11px;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.96492 1.17052C1.5744 0.779996 0.94123 0.779996 0.550706 1.17052C0.160182 1.56104 0.160181 2.19421 0.550706 2.58473L4.76815 6.80218C4.77634 6.81097 4.78471 6.81964 4.79326 6.8282C5.18379 7.21872 5.81695 7.21873 6.20748 6.8282L10.4501 2.58556C10.8406 2.19504 10.8406 1.56187 10.4501 1.17135C10.0596 0.780822 9.42643 0.780821 9.03591 1.17135L5.50082 4.70643L1.96492 1.17052Z' fill='black'/%3E%3C/svg%3E");
            background-position: center center;
            background-repeat: no-repeat;
          }

          .form-field-label {
            display: block;
            position: absolute;
            color: var(--wp--preset--color--medium-gray);
            top: 1.18rem;
            font-size: 0.875rem;
            left: 0.7rem;
            line-height: 0.875rem;
            margin: 0;
            padding: 0;
            transition: all 0.2s ease;
          }

          .password-checklist,
          .password-verification-checklist {
            overflow: hidden;
            transition: max-height 0.3s ease-out;
            max-height: 0;

            &-list {
              display: flex;
              flex-wrap: wrap;
              list-style-type: none;
              padding-left: 1.25rem;
              margin-top: $spacer * 0.25;
            }

            &-item {
              width: 100%;
              color: var(--wp--preset--color--toyota-red);
              font-size: 0.75rem;
              line-height: 0.625rem;
              margin-top: 0;
              margin-bottom: $spacer * 0.25;
              margin-left: 0rem;
              padding: 0px 0px 2px 0px;
              position: relative;

              @media screen and (max-width: 992px) {
                width: 50%;
              }

              &::before {
                display: block;
                position: absolute;
                top: 0.18rem;
                left: -0.9rem;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='close' fill-rule='evenodd' clip-rule='evenodd' d='M1.30248 0.22347L3.99951 2.92067L6.69752 0.22347C6.99548 -0.07449 7.47857 -0.07449 7.77653 0.22347C8.07449 0.52143 8.07449 1.00452 7.77653 1.30248L5.07912 3.99951L7.77653 6.69752C8.07449 6.99548 8.07449 7.47857 7.77653 7.77653C7.47857 8.07449 6.99548 8.07449 6.69752 7.77653L3.99951 5.07912L1.30248 7.77653C1.00452 8.07449 0.52143 8.07449 0.22347 7.77653C-0.07449 7.47857 -0.07449 6.99548 0.22347 6.69752L2.92067 3.99951L0.22347 1.30248C-0.07449 1.00452 -0.07449 0.52143 0.22347 0.22347C0.52143 -0.07449 1.00452 -0.07449 1.30248 0.22347Z' fill='%23EB0A1E'/%3E%3C/svg%3E");
                width: 8px;
                height: 8px;
                background-position: center center;
                background-repeat: no-repeat;
              }

              &-valid {
                color: var(--wp--preset--color--validation-green);

                &::before {
                  padding-right: 1rem;
                  display: block;
                  position: absolute;
                  top: 0.25rem;
                  left: -1.4rem;
                  content: '';
                  background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Path' fill-rule='evenodd' clip-rule='evenodd' d='M3.53294 5.62003C3.33591 5.82684 3.00596 5.82684 2.80893 5.62003L2.28449 5.06956L0.174062 2.85592C-0.0580205 2.61232 -0.0580205 2.21736 0.174062 1.97376C0.406144 1.73016 0.782423 1.73016 1.01451 1.97376L3.12484 4.1875L6.92996 0.192701C7.17475 -0.0642336 7.57162 -0.0642336 7.81641 0.192701C8.0612 0.449635 8.0612 0.866208 7.81641 1.12314L4.01129 5.11794L3.53294 5.62003Z' fill='%23009B0D'/%3E%3C/svg%3E%0A");
                  width: 8px;
                  height: 6px;
                  background-position: center center;
                  background-repeat: no-repeat;
                }
              }
            }
          }

          #password.hasValue+.password-checklist {
            max-height: 100px;
            /* Adjust the height as needed */
          }

          #password_verification.hasValue+.password-checklist-container .password-checklist {
            max-height: 100px;
            /* Adjust the height as needed */
          }

          #password_new.hasValue+.password-checklist-container .password-checklist {
            max-height: 100px;
            /* Adjust the height as needed */
          }

          .form-field-row {
            display: flex;
            gap: $spacer * 0.5;
          }

          .form-file-upload {
            &-row {
              display: flex;
              gap: 0;

              .form-field {
                border-radius: 0.5rem 0 0 0.5rem;
                margin-bottom: 0.375rem;
              }

              button {
                border-radius: 0 0.5rem 0.5rem 0;
                color: var(--wp--preset--color--white);
                background-color: var(--wp--preset--color--black);
                border: none;
                margin: 0;
                padding: 0 $spacer;
                max-height: 3.3125rem;
                font-family: var(--wp--preset--font-family--toyota-type-semibold);
              }
            }

            .subnote {
              font-size: 0.625rem;
              line-height: 1.125rem;
            }
          }

          .form-forgot-password-link-container {
            a {
              color: var(--wp--preset--color--link-blue);
              font-size: 0.625rem;
            }
          }

          .form-option-check {
            display: flex;
            height: 1.375rem;

            input {
              display: flex;
              justify-content: flex-start;
              width: 1.375rem;
              height: 1.25rem;
              margin-right: $spacer * 0.25;
            }

            label {
              margin-top: -0.1875rem;
            }
          }

          .form-button-submit,
          .form-button-continue {
            width: 100%;
          }

          .upload-logo-field {
            h3 {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.75rem;
              margin-bottom: 1.25rem;
              font-family: var(--wp--preset--font-family--toyota-type-semibold);
            }

            .form-field-input-fileupload {
              display: flex;
              padding: 0.75rem;
              justify-content: center;
              border: 1px solid var(--wp--preset--color--light-gray);
              border-radius: 0.5rem;
              font-size: 1rem;
              width: 100%;
              font-family: var(--wp--preset--font-family--toyota-type-book);
            }
          }

        }

        &-form-invite {

          .invite-form-row {
            margin-bottom: 1rem;
          }

          button {
            width: 100%;
          }

          .invite-add,
          .send-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: unset;

            span {
              font-size: 0.875rem;
              font-family: var(--wp--preset--font-family--toyota-type-semibold);
              line-height: 0.875rem;
              display: block;
            }

            &-link {
              &:hover {
                text-decoration: underline !important;
              }
            }

            &-plus {
              display: block;
              background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7L9 7V2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2L7 7L2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9L7 9V14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14L9 9H14Z' fill='%23E10A1D'/%3E%3C/svg%3E%0A");
              width: $spacer;
              height: $spacer;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }

          .send-button {
            &-icon {
              margin-left: $spacer * 0.25;
              display: block;
              background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon'%3E%3Cpath id='handle' d='M3.64453 12.1144V9.64834L10.008 3.01172L7.46259 9.64834L5.17308 12.6331C5.01178 12.8434 4.76184 12.9666 4.49683 12.9666C4.02612 12.9666 3.64453 12.5851 3.64453 12.1144Z' fill='black'/%3E%3Cpath id='plane' fill-rule='evenodd' clip-rule='evenodd' d='M13.6602 0.0560145L0.843569 5.96446C0.428025 6.15602 0.378513 6.72686 0.754869 6.98711L2.91145 8.47839L8.46417 4.09016C8.61476 3.97115 8.80604 4.17552 8.67733 4.31792L4.43564 9.53237L9.29926 12.8956C9.61809 13.116 10.0593 12.975 10.1911 12.6104L14.4633 0.793554C14.6416 0.300217 14.1366 -0.163607 13.6602 0.0560145Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
              width: 15px;
              height: 13px;
              background-position: center center;
              background-repeat: no-repeat;
              transition: 0.3s;
            }

            &:hover {
              .send-button-icon {
                filter: invert(100%);
              }
            }
          }

          .form-field-row {
            align-items: center;

            div {
              flex-basis: unset;
            }

            .form-field-invite-email {
              width: 60%;
            }

            .form-field-invite-first-name {
              width: 35%;
            }

            .form-field-delete-entry {
              width: 5%;

              a,
              button {
                display: block;
                background-color: unset;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='UtiltyIcon'%3E%3Cpath id='close' fill-rule='evenodd' clip-rule='evenodd' d='M4.46492 3.05071L7.99981 6.58581L11.536 3.05071C11.9265 2.66018 12.5597 2.66018 12.9502 3.05071C13.3407 3.44123 13.3407 4.0744 12.9502 4.46492L9.41481 7.99981L12.9502 11.536C13.3407 11.9265 13.3407 12.5597 12.9502 12.9502C12.5597 13.3407 11.9265 13.3407 11.536 12.9502L7.99981 9.41481L4.46492 12.9502C4.0744 13.3407 3.44123 13.3407 3.05071 12.9502C2.66018 12.5597 2.66018 11.9265 3.05071 11.536L6.58581 7.99981L3.05071 4.46492C2.66018 4.0744 2.66018 3.44123 3.05071 3.05071C3.44123 2.66018 4.0744 2.66018 4.46492 3.05071Z' fill='%23C0C0C0'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-position: center center;
                background-repeat: no-repeat;
                width: 1rem;
                height: 1rem;
                border: none;


                &:hover {
                  filter: brightness(0);
                }
              }
            }
          }
        }

        .verification-code-input {
          display: flex;
          margin-top: $spacer * 0.5;
          margin-bottom: $spacer * 0.25;
          flex-wrap: nowrap;
          width: 100%;

          input {
            border-radius: 0.5rem;
            outline: 0;
            border: 1px solid var(--wp--preset--color--light-gray);
            width: 3rem;
            height: 3rem;
            margin-right: 0.5rem;
            display: flex;
            padding: 0.75rem;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex-shrink: 1;
            align-self: stretch;
            text-align: center;
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: -0.01rem;

            @media (max-width: 520px) {
              width: 2.5rem;
              height: 2.5rem;
              font-size: 1rem;
              padding: 0.5rem;
            }

            @media (min-width: 781px) and (max-width: 900px) {
              width: 2.5rem;
              height: 2.5rem;
              font-size: 1rem;
              padding: 0.5rem;
            }

                        @media screen and (max-width: 992px) {
              width: 3.5rem;
              height: 3.5rem;
              padding: 1rem;
            }



          }

          .input-active {
            border: 1px solid var(--wp--preset--color--black);
          }
        }

        .clear-sm {
          margin-bottom: $spacer * 0.1;
        }

        .clear-md {
          margin-bottom: $spacer * 0.7969;
        }

        .clear-lg {
          margin-bottom: $spacer * 1.75;
        }

      }

    }


    .form-hidden {
      display: none;
    }

    .create-account,
    .registration-form-sign-in-wrapper {
      display: flex;
      // justify-content: center;

      span,
      p {
        font-family: var(--wp--preset--font-family--toyota-type-book);
      }

      a {
        font-family: var(--wp--preset--font-family--toyota-type-semibold); 
      }
    }


  }


  .form-registration {
    .form-box-content {
      &-intro {
        h2 {
          font-size: 1.25rem;
          line-height: 1.75rem;
          letter-spacing: -0.03125rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }

  .form-checklist-anchorbox {
    display: none;
    border-radius: 0.5rem;
    background: linear-gradient(12deg, #000 1.11%, #171C36 100.91%), var(--color-color-anchor-navy, #171C36);
    color: var(--wp--preset--color--white);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6.5rem;

    @media screen and (max-width: 768px) {
      display: flex;
    }


    &-content {

      h1 {
        font-size: 2.5rem;
        font-family: var(--wp--preset--font-family--toyota-type-semibold);
        font-weight: normal;
        line-height: 3rem;
        letter-spacing: -0.0125rem;
        margin-top: 0;
        margin-bottom: 2.8rem;
        color: var(--wp--preset--color--white);
      }

      p {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      .registration-tracker {
        padding-left: 3.3rem;
        list-style: none;
        position: relative;
        margin-bottom: 3rem;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 10%;
          left: 1.10rem;
          background-color: var(--wp--preset--color--white);
          width: 0.125rem;
          height: 80%;
        }

        &-item {
          font-family: var(--wp--preset--font-family--toyota-type-light);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.75rem;
          letter-spacing: -0.00313rem;
          position: relative;
          margin-bottom: 2rem;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0.5rem;
            left: -3.3rem;
            width: 2rem;
            height: 2rem;
            padding: 0;
            justify-content: center;
            align-items: center;
            border-radius: 20rem;
            border: 2px solid #F3F4FA;
            background: #283160;
          }

          &-active {

            font-family: var(--wp--preset--font-family--toyota-type-semibold);

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0.5rem;
              left: -3.3rem;
              width: 2rem;
              height: 2rem;
              padding: 0;
              justify-content: center;
              align-items: center;
              border-radius: 20rem;
              border: 2px solid #F3F4FA;
              background-image: radial-gradient(circle, white 35%, #283160 36%);
            }

          }

          &-complete {

            font-family: var(--wp--preset--font-family--toyota-type-regular);

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0.5rem;
              left: -3.3rem;
              width: 2rem;
              height: 2rem;
              padding: 0;
              justify-content: center;
              align-items: center;
              border-radius: 20rem;
              border: 2px solid #F3F4FA;
              background-color: var(--wp--preset--color--validation-green);
              background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Step'%3E%3Cpath id='Step_2' fill-rule='evenodd' clip-rule='evenodd' d='M6.29045 12.8014C6.52723 12.7881 6.76017 12.691 6.94105 12.5101C6.94936 12.5018 6.95749 12.4934 6.96544 12.4849L14.0112 5.4391C14.4017 5.04858 14.4017 4.41541 14.0112 4.02489C13.6207 3.63436 12.9875 3.63436 12.597 4.02489L6.23352 10.3884L3.40552 7.56037C3.015 7.16985 2.38183 7.16985 1.99131 7.56037C1.60078 7.95089 1.60078 8.58406 1.99131 8.97458L5.50015 12.4834C5.50855 12.4925 5.51714 12.5014 5.52593 12.5102C5.7358 12.72 6.01575 12.8171 6.29045 12.8014Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
              background-position: center center;
              background-repeat: no-repeat;
            }

          }
        }

        &-subtext {
          display: block;
          color: #CFD4EB;
          font-family: var(--wp--preset--font-family--toyota-type-book);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.375rem;
        }
      }
    }
  }

  .info-note {
    @include info-note-icon-text;
  }

  .step-nav {
    display: flex;
    gap: $spacer;
    justify-content: space-between;
    padding: 0;

    button {
      width: max-content;
      flex-basis: 50%;
      padding: 0.5rem 1.5rem;
      background-color: transparent;
    }

    .is-style-outline-white {
      border-radius: 3.125rem;
      border: 1px solid var(--wp--preset--color--black);
      color: var(--wp--preset--color--black);

      &:hover {
        background-color: var(--wp--preset--color--black);
        border: 1px solid var(--wp--preset--color--black);
        color: var(--wp--preset--color--white);
      }
    }
  }

  .form-box-content-terms {
    width: 100%;
    max-width: 100% !important;
    margin-left: 2rem;

    .form-box-content-intro {
      p {
        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }

    .info-note {
      @include info-note-icon-text;
      margin-bottom: 0.725rem;

      .info-note-icon {
        padding-right: 0.7rem;
      }

      p {
        font-family: var(--wp--preset--font-family--toyota-type-semibold);
        color: var(--wp--preset--color--black);
      }
    }

  }

  .form-box-content-form-success {
    .wp-element-button {
      margin-bottom: 0.5rem;
    }
  } 
}