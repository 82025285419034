/* This is a compiled file, you should be editing the file in the templates directory */

.home {
  #page {
    //opacity: 0;
  }
}
.QOverlay {
  background-color: #000000;
  z-index: 9999;
}
.QLoader {
  background-color: #fff;
  height: 1px;
}
//splash
.splash-section {
  background: #fff;
  position: relative;
  z-index: 1;
  min-height: 500px;
  height: calc(100vh - 69px);
  width: 100%;
  margin-top: 0px;
  overflow: hidden;
  .row {
    .col:first-child {
      .content-desc {
        background: #000;
        .entry {
          &:before {
            content: "";
            width: 100vw;
            height: calc(100vh - 69px);
            background-image: url(../images/home-car.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 30vw;
            top: 0;
            right: -50vw;
            position: absolute;
            display: inline-block;
            z-index: 1;
          }
        }
      }
    }
  }
  .content-desc {
    background: $red;
    display: table;
    table-layout: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px;
    width: 100.5%;
    height: calc(100vh - 69px);
    .entry {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      text-align: center;
      position: relative;
      .entry-wrap {
        position: relative;
        z-index: 2;
        max-width: 430px;
        margin: auto;
      }
      span {
        color: #fff;
      }
      h3 {
        color: #fff;
        font-size: 30px;
        line-height: 1.1;
        font-weight: 800;
        margin: 5px 0 20px;
        @include easing();
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background: rgba(0, 0, 0, 0.0); //background: rgba(0, 0, 0, 0.15);
        @include easing();
        z-index: 1;
      }
      &:hover {
        &:after {
          background: hsla(0, 0%, 0%, 0.75);
          z-index: 1;
        }
        .view_hover {
          opacity: 1;
          -webkit-transform: translateY(0);
          transform: translateY(0);
          transition: opacity 1s cubic-bezier(.19, 1, .22, 1), -webkit-transform 1s cubic-bezier(.19, 1, .22, 1);
          transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1);
          transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1), -webkit-transform 1s cubic-bezier(.19, 1, .22, 1);
          transition-delay: .2s;
          &.view_delay {
            transition-delay: .1s;
          }
        }
        h3 {}
      }
      .view_hover {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
      .btn.btn-primary {
        border: 1px solid #fff;
        &:hover {
          border-color: $red;
        }
      }
    }
  }
}
//Landing Page
.landing-slider {
  .slider-fade {
    .slider_img {
      height: 470px;
      margin-bottom: 0;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      position: relative;
      .container, .row, [class*='col-'] {
        height: 100%;
      }
      .slider-wrap {
        @include vertical-align;
      }
      .slider-desc {
        display: block;
        padding: 20px;
        background: rgba(23, 23, 23, 0.62);
        color: #fff;
        h3 {
          font-size: 32px;
          line-height: 1.2;
        }
        p {
          font-size: 18px;
          line-height: 1.5;
        }
        .btn {
          margin-right: 15px;
          padding: 10px 5px;
          &:hover {
            border-color: #fff;
            color: #fff;
            background: none;
          }
          &.alt-btn {
            border-color: #fff;
            &:hover {
              border-color: $red;
              background: $red;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.tag {
  .overlay-articles a, .overlay-articles a:hover {
    color: $drk;
  }
}
.blog-section {
  overflow: hidden;
  .overlay-entry {
    h3 {
      font-family: "ToyotaType-Semibold";
    }
  }
  .entry span {
    font-size: 14px;
    i {
      position: relative;
    }
  }
}
.overlay-articles {
  overflow: hidden;
  article {
    margin-bottom: 30px;
  }
  a, a:hover {
    color: #fff;
  }
  .thumbnail {
    position: relative;
    overflow: hidden;
    @include easing;
    .info-overlay {
      position: absolute;
      bottom: 0;
      top: 65%;
      z-index: 10;
      width: 100%;
      @include cubic_slow;
      opacity: 1;
      background: rgba(0, 0, 0, 0.75);
      padding: 0px 0 0px;
      .overlay-entry {
        padding: 15px 15px 5px;
        text-align: center;
        padding-bottom: 4.5rem;
        h3 {
          font-size: 18px;
          line-height: 1.3;
          font-family: "ToyotaType-Semibold";
          margin: 0 0 8px;
        }
        p {
          opacity: 0;
          -webkit-transform: translateY(20px);
          transform: translateY(20px);
          margin: 0;
          padding: 0 4%;
          font-size: 14px;
          line-height: 1.3;
        }
        .fade_up {
          opacity: 0;
          -webkit-transform: translateY(20px);
          transform: translateY(20px);
          margin: 10px 0 0;
          font-size: 14px;
          line-height: 1.3;
          text-align: left;
          padding: 0;
          li {
            list-style: none;
            display: inline-block;
            width: 100%;
            margin: 0 0 7px;
            i {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: $red;
              margin: 0 7px 2px 0;
              position: relative;
              top: 6px;
              float: left;
              max-width: 8px;
              width: 20%;
            }
            span {
              display: inline-block;
              float: left;
              width: 83%;
            }
          }
        }
      }
      .bottom-info {
        position: absolute;
        width: calc(100% - 64px);
        height: 53px;
        bottom: 0;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        .entry {
          padding: 10px 20px 15px;
        }
        span {
          i {
            position: relative;
          }
        }
        .readmore {
          width: 30px;
          height: 30px;
          line-height: 28px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
      .info-overlay {
        padding: 0;
        top: 20%;
        .overlay-entry {
          p, .fade_up {
            opacity: 1;
            -ms-transform: translateY(0);
            transform: translateY(0);
            transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1);
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
          }
        }
        .bottom-info {
          background-color: rgba(200, 10, 10, .7);
          opacity: 1;
          -ms-transform: translateY(0);
          transform: translateY(0);
          transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1);
          -webkit-transition-delay: 0.24s;
          transition-delay: 0.24s;
        }
      }
    }
  }
}
@media (min-width: 1550px) {
  .overlay-articles .type-collision_pros_pub .thumbnail:hover .info-overlay {
    top: 40%;
  }
}
@media (min-width:1600px) {
  .overlay-articles .type-collision_pros_pub .thumbnail:hover .info-overlay {
    top: 50%;
  }
}
.overlay-articles .type-collision_archive .thumbnail:hover .info-overlay {
  top: 0;
}
.search {
  .overlay-articles .thumbnail:hover .info-overlay {
    top: 0;
  }
}
.products-sections.overlay-articles .thumbnail .info-overlay .bottom-info span i {
  position: relative;
  top: 1px;
  margin-left: 4px;
}
.overlay-articles .type-collision_archive {
  .thumbnail .info-overlay .overlay-entry {
    padding: 15px 20px 5px;
    text-align: left;
    padding-bottom: 4.5rem;
    h3 {
      max-width: 95%;
      font-size: 14px;
      text-transform: uppercase;
      ul li {
        font-size: 11px;
      }
    }
  }
}
.overlay-articles .type-videos {
  .thumbnail {
    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}
.products-sections.overlay-articles {
  .thumbnail .info-overlay .overlay-entry h3 {
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    font-family: "ToyotaType-Semibold";
    margin: 0 0 10px;
  }
}
.section-entry {
  margin: 0 0 30px;
  h2 {
    font-size: 48px;
    line-height: 1.3;
    margin: 0 0 15px;
  }
  p {
    margin: 0;
    max-width: 780px;
  }
  .micro_img {
    text-indent: -999em;
    display: block;
    background: url(../images/microcat-market.jpg) 0 0 no-repeat;
    background-size: 240px;
    height: 76px;
  }
}
//columns-section
.columns-section.alt-columns {
  .thumbnail .img-holder {
    background-color: transparent;
    height: 95px;
    line-height: 95px;
    padding: 0 40px;
    text-align: center;
    width: inherit;
    img {
      max-height: 100px;
      display: inline-block;
      vertical-align: middle;
      height: auto;
      width: inherit;
    }
  }
}
.columns-section.no_hover_state {
  .thumbnail .overlay {
    position: relative;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
    z-index: 11;
    margin: 0;
    text-align: center;
    transition: unset;
    display: inherit;
    span {
      position: relative;
      padding: 9px 8px;
      transition: inherit;
      transition: inherit;
      transition: inherit;
      color: $red;
      background: none;
      border: 1px solid $red;
      background: #fff;
      border-radius: 0;
      padding: 10px 40px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1;
      top: inherit;
      left: inherit;
      opacity: 1;
      @include easing();
      -webkit-transform: none;
      transform: none;
    }
    &:hover {
      span {
        background: $red;
        color: #fff;
      }
    }
  }
}
.columns-section {
  .article:last-child {
    .thumbnail {
      &:after {
        display: none;
      }
    }
  }
  .thumbnail {
    position: relative;
    padding: 20px 30px 20px 0;
    margin: 0px;
    text-align: center;
    height: 100%;
    min-height: 100%;
    .img-holder {
      font-weight: 400;
      font-size: 32px;
      color: #fff;
      margin-left: 0;
      background-color: $red;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      margin: auto;
      text-align: center;
    }
    .img-circle {
      width: 100%;
      height: inherit;
    }
    .desc {
      padding: 10px 0px 0 0;
      h4 {
        margin: 20px 0 15px;
        font-size: 18px;
        line-height: 1;
      }
      p {
        font-weight: 300;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      bottom: -25px;
      width: 1px;
      background: #c1c1c1;
      right: 0;
      height: 100%;
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0px;
      right: 25px;
      z-index: 11;
      margin: 0;
      text-align: center;
      @include easing;
      span {
        position: absolute;
        padding: 9px 8px;
        -webkit-transition: opacity .3s, -webkit-transform .3s;
        transition: opacity 0.3s, transform 0.3s;
        color: $red;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translateY(-50%) translateX(-50%) scale(.25);
        transform: translateY(-50%) translateX(-50%) scale(.25);
      }
    }
    &:hover {
      .overlay {
        opacity: 1;
        background: hsla(0, 0%, 100%, 0.76);
        span {
          opacity: 1;
          -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
          transform: translateY(-50%) translateX(-50%) scale(1);
        }
      }
    }
  }
}
.split-section {
  background: #292929;
  position: relative;
  .content {
    padding: 0 45px;
    color: #fff;
    h3 {
      margin: 0 0 20px;
      font-size: 48px;
    }
    ul {
      margin: 0px 0 30px;
      padding: 0;
      li {
        display: block;
        margin: 0 0 3px;
        list-style: none;
      }
    }
    .download-links {
      margin-top: 30px;
    }
  }
  .bg-img {
    background-image: url(../images/traffic-car-vehicle-black.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 450px;
    max-height: 600px;
    height: calc(100vh - 300px);
    width: 100%;
    z-index: 1;
    margin: 0;
    overflow: hidden;
  }
  &.collision-pros {
    background-image: url(../images/news_cpfall.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 1;
    .content {
      max-width: 410px;
      padding: 100px 30px;
      margin: auto;
      text-align: center;
      h3 {
        margin: 0 0 20px;
        font-size: 32px;
      }
      .form-control {
        border-radius: 0;
      }
      .left-content {
        position: absolute;
        left: 30px;
        bottom: 30px;
        text-align: left;
        .downloads {
          margin: 0;
          a {
            color: #000;
          }
        }
      }
    }
  }
}
//Collision Pros Magazine
.hero-banner {
  position: relative;
  figure img {
    width: 100%;
    height: inherit;
  }
  .overlay_content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    padding: 30px;
    background: rgba(0, 0, 0, 0.70);
    h1 {
      font-family: "ToyotaType-Semibold";
      font-size: 16px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #fff;
    }
    span {
      display: block;
      font-family: "ToyotaType-Semibold";
      font-size: 16px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #fff;
    }
	
	.play_wrapper { 	
		color: #fff;
    background: $red;
    border-color: $red;
    display: inline-block;
    margin: 15px 0 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    font-family: ToyotaType-Semibold;
    transition: all .3s ease-in-out;

     
      .audiojsZ {
        width: 100%;
        height: 40px;
        overflow: hidden;
        font-family: ToyotaType-Semibold;
        font-size: 12px;
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .play-pauseZ {
          width: 100%;
          height: 40px;
        }
        .playZ {
          width: 100%;
          background: none;
          position: relative;
          i {
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -11px;
            font-size: 22px;
            /* line-height: 40px; */
          }
        }
        .pauseZ {
          width: 100%;
          background: url(../images/player-graphics.gif) 100% -87px no-repeat;
        }
      }
    }
  }
}
.content-section {
  background: #f5f5f5;
  padding: 65px;
  h3 {
    font-family: "ToyotaType-Semibold";
    text-transform: uppercase;
    margin: 0 0 30px;
  }
  p {
    max-width: 650px;
    margin: auto;
  }
  &.no-bg {
    background: none;
  }
  &.bg-img {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}
//Archive
.section-title {
  h3 {
    font-family: "ToyotaType-Semibold";
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    margin: 0 0 30px;
  }
}
//Search Results
.search .results {
  font-family: "ToyotaType-Semibold";
  font-size: 24px;
  color: $red;
  display: block;
  margin: 10px 0 0;
}
.autocomplete-suggestion {
  padding: 3px .6em;
  margin: 0 0 10px;
  .img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    transition: all .2s ease-in-out;
    margin: 0 10px 0px 0;
  }
  b {
    font-family: "ToyotaType-Semibold";
    color: $drk;
  }
}
//audio imgs
.overlay-icons.type-audio, {
  .thumbnail {
    height: 100%;
    background: linear-gradient(to right, #edf0f5, rgb(238, 242, 243));
    padding: 6em 1em;
    .desc-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
//Media Library
.movie--isloading {
  height: 252px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
}
.movie--isloading .loading-image {
  height: 190px;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
  animation: shine-loading-image 2s infinite ease-out;
}
.movie--isloading .loading-content {
  background: #f7f7f7;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.movie--isloading .loading-content .loading-text-container {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.movie--isloading .loading-content .loading-main-text {
  height: 10px;
  width: 65%;
  margin-bottom: 10px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
  animation: shine-loading-container-items 2s infinite ease-out;
}
.movie--isloading .loading-content .loading-sub-text {
  height: 10px;
  width: 50%;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
  animation: shine-loading-container-items 2s infinite ease-out;
}
.movie--isloading .loading-content .loading-btn {
  width: 60px;
  height: 25px;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 3px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
  animation: shine-loading-container-items 2s infinite ease-out;
}
@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
.page-template-template-media-library, .search-results {
  .overlay-icons.type-audio .thumbnail .desc-txt {
    text-align: center;
  }
  .play-btn {
    background: none;
    padding: 0;
    border-radius: 50%;
		border: 1px solid $drk;
    width: 50px;
    height: 50px;
		display: inline-block;
    border-color: $drk;
    .audiojsZ {
      width: 50px;
      height: 50px;
    }
  }
  .audiojs {
    background: none;
    width: 50px;
    height: 50px;
    overflow: hidden;
    box-shadow: none;
    .play-pause {
      border: none;
      width: 47px;
      height: 50px;
      text-align: center;
      padding: 0;
      margin: 0px;
      float: none;
      overflow: hidden;
    }
    .play {
      background: none;
      width: 50px;
      height: 50px;
      &:before {
        content: "\f259";
        font-size: 23px;
        display: inline-block;
        font-family: Ionicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 49px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    .pause {
      background: none;
      width: 50px;
      height: 50px;
      &:before {
        content: "\f478";
        font-size: 23px;
        display: inline-block;
        font-family: Ionicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 49px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
//App Charts
.application-charts {
  .overlay-icons {
    margin-bottom: 60px;
  }
}
//overlay-icons
.overlay-icons {
  figure {
    position: relative;
    overflow: hidden;
  }
  figure:hover figcaption {
    opacity: 1;
    background: rgba(21, 21, 21, 0.76);
    .read-more {
      opacity: 1;
      -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
      transform: translateY(-50%) translateX(-50%) scale(1);
      .vimeo-modal {
        background: hsla(0, 0%, 44%, 0.66);
        i {
          color: hsla(0, 0%, 100%, 0.94);
        }
      }
    }
  }
  figcaption {
    position: absolute;
    top: 0;
    z-index: 11;
    padding: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
    @include easing;
    a {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .read-more {
      position: absolute;
      padding: 9px 30px;
      border: none;
      font-weight: bold;
      -webkit-transition: opacity .3s, -webkit-transform .3s;
      transition: opacity 0.3s, transform 0.3s;
      color: #FCFCFC;
      text-transform: uppercase;
      font-size: 1.6em;
      line-height: 1;
      top: 50%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translateY(-50%) translateX(-50%) scale(.25);
      transform: translateY(-50%) translateX(-50%) scale(.25);
    }
    .play-icon {
      display: block;
      background: hsla(0, 0%, 100%, 0.54);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      i {
        line-height: 50px;
        font-size: 32px;
        color: hsla(0, 0%, 100%, 0.94);
      }
    }
  }
  .desc-txt {
    padding: 20px 0px 25px;
    h3 {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 600;
      text-transform: uppercase;
      span {
        text-transform: none;
        display: block;
        font-weight: 600;
        color: #000;
      }
    }
    h4 {
      font-size: 22px;
      line-height: 1;
      margin: 0 0 10px;
    }
  } //promos
  .col-item {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #dcdcdc;
    background: #f7f7f7;
    .downloads {
      margin: 10px 0 0;
      li {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .desc-txt {
      padding: 0 0 20px;
      ul {
        padding: 0;
        margin: 0 0 20px;
        li {
          list-style: none;
        }
      }
    }
    .print-label {
      .btn {
        margin-right: 15px;
      }
    }
  }
  .section-title {
    max-width: 750px;
  }
}
.downloads {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0 0 5px;
    display: block;
    a {
      text-transform: uppercase;
      font-size: 14px;
    }
    i {
      position: relative;
      top: 7px;
      margin-right: 10px;
    }
  }
}
//Dealer
.dealer_loc {
  .wpsl-map {
    height: 600px !important;
    border: none;
    border-radius: 0;
  }
  .wpsl-input label, #wpsl-radius label, #wpsl-category label {
    width: inherit;
  }
  #wpsl-search-wrap {
    .wpsl-input {
      margin-right: 35px;
      border-radius: 0;
    }
    .wpsl-search-btn-wrap {
      float: right;
      margin-right: 0;
      #wpsl-search-btn {
        background: none;
        border: 1px solid $red;
        color: $red;
        border-radius: 0;
        padding: 8px 30px;
      }
    }
  }
  .wpsl-direction-after {
    display: none;
  }
  #wpsl-wrap {
    #wpsl-result-list {
      li {
        padding: 25px;
        border-bottom: none;
        border: 1px solid #fff;
        margin-left: 5px;
        margin-right: 15px;
        margin-bottom: 30px;
        overflow: hidden;
        list-style: none!important;
        text-indent: 0;
        box-shadow: 2px 2px 8px #ccc;
        &:hover {
          border: 1px solid $red;
        }
        .dealer_marker {
          background: url(../images/markers/my_marker@2x.png) 0% 3px no-repeat;
          width: 100%;
          height: inherit;
          display: inline-block;
          background-size: 20px;
          padding-left: 35px;
          padding-bottom: 20px;
          strong {
            font-size: 20px;
            line-height: 1.1;
            margin: 0;
            display: block;
            font-weight: normal;
            position: relative;
            top: 3px;
          }
          &.Star {
            background: url(../images/Starelite@2x-2.png) 0% 3px no-repeat;
            background-size: 20px;
          }
        }
        .wpsl-store-location>p>span {
          font-size: 14px;
          line-height: 1.2;
          color: #333;
        }
      }
    }
  }
  #wpsl-gmap {}
  .wpsl-contact-details {
    font-size: 14px;
  }
}
#wpsl-gmap img[src*="blue@2x"] {
  display: none !important;
}
@media (min-width: 720px) {
  #wpsl-result-list {
    width: 50%;
    margin-right: .5%;
  }
  #wpsl-gmap {
    float: right;
    width: 50%;
    height: 450px;
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .columns-section .thumbnail:after {
    display: none;
  }
}
//Responsive
@media (max-width: 992px) {
  .overlay-icons .col-item .print-label .btn {
    margin-right: 12px;
  }
  .splash-section .content-desc .entry .view_hover {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .splash-section .content-desc .entry:after {}
  .splash-section .content-desc .entry .entry-wrap h3 {
    font-size: 28px;
  }
  .split-section .content h3 {
    font-size: 34px;
  }
  .section-entry h2 {
    font-size: 34px;
  }
}
@media (max-width: 768px) {
  //splash
  .splash-section .content-desc .entry .entry-wrap {
    max-width: 340px;
  }
  .splash-section {
    height: inherit;
    min-height: 440px;
  }
  .splash-section .content-desc {
    height: inherit;
    min-height: 440px;
  }
  .landing-slider .slider-fade .slider_img .slider-wrap {
    position: initial;
    top: initial;
    -webkit-transform: inherit;
    transform: inherit;
  }
  .landing-slider .slider-fade .slick-slide .slider-desc {
    position: absolute;
    top: inherit;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .splash-section .content-desc .entry span {
    font-size: 12px;
  }
  .landing-slider .slider-fade .slider_img .slider-desc h3 {
    font-size: 24px;
  }
  .slick-next, .slick-prev {
    display: none !important;
  }
  .section-entry h2 {
    font-size: 36px;
  }
  .columns-section .thumbnail:after {
    display: none;
  }
  .columns-section .thumbnail {
    padding: 20px 0 20px 0;
  }
  .vertical-align {
    position: relative;
    top: 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  .split-section .content {
    padding: 40px 30px 40px;
    color: #fff;
  }
  .split-section .content h3 {
    margin: 0 0 20px;
    font-size: 32px;
  }
  .split-section .bg-img {
    min-height: 400px;
    max-height: inherit;
    height: inherit;
  }
  .overlay-icons .desc-txt {
    padding: 10px 0 25px;
    h4 {
      font-size: 18px;
      margin: 0 0 20px;
    }
  }
  .overlay-icons .col-item .print-label .btn {
    margin-right: 0;
    display: block;
    margin-bottom: 20px;
  }
  .products-sections.overlay-articles .thumbnail .info-overlay .overlay-entry h3 {
    font-size: 20px;
    text-transform: none;
    color: $drk;
  } //show hidden overlay text
  .overlay-articles .thumbnail .info-overlay {
    position: relative;
    bottom: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    transition: all .3s ease-in-out;
    opacity: 1;
    background: rgb(255, 255, 255);
  }
  .overlay-articles .thumbnail .info-overlay .overlay-entry .fade_up {
    opacity: 1;
    transform: translateY(0px);
  }
  .overlay-articles .thumbnail .info-overlay .overlay-entry {
    color: $drk;
    text-align: left;
    padding: 15px 0px 5px;
  }
  .overlay-articles .thumbnail:hover .info-overlay .bottom-info {
    background-color: transparent;
  }
  .overlay-articles .thumbnail:hover {
    box-shadow: none;
  }
  .overlay-articles .thumbnail .info-overlay .bottom-info {
    position: relative;
    background-color: transparent;
    width: calc(100% - 64px);
    height: 53px;
    bottom: 0;
    width: 100%;
    text-align: right;
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .overlay-articles .thumbnail .info-overlay .overlay-entry p {
    opacity: 1;
    transform: translateY(0);
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.3;
  }
  .overlay-articles .thumbnail .info-overlay .bottom-info .readmore {
    border: 1px solid $drk;
    text-align: center;
  }
  .products-sections.overlay-articles .thumbnail .info-overlay .bottom-info span {
    color: $drk;
  }
  .overlay-articles .thumbnail .info-overlay .bottom-info span i {
    color: $drk;
  }
  .dealer_loc #wpsl-wrap #wpsl-result-list li .dealer_marker strong {
    font-size: 16px;
    line-height: 1.1;
  }
  .hero-banner .overlay_content {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 30px 15px;
    background: none;
    h1 {
      color: $drk;
    }
    span {
      color: $drk;
    }
  }
  .content-section {
    padding: 65px 15px;
  }
  .overlay-articles article {
    padding: 0 30px;
  }
  .content-section h3 {
    font-size: 22px;
  }
}
@media (max-width: 668px) {
  .splash-section {
    min-height: 420px;
    height: inherit;
    .content-desc .entry {
      .view_hover {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
      .entry-wrap {
        padding: 30px;
        h3 {
          font-size: 26px;
          line-height: 1.2;
        }
      }
    }
  }
  .splash-section .row .col:first-child .content-desc .entry:before {
    height: 100%;
    background-position: center;
    background-size: 45vw;
  }
  .splash-section .row .col:last-child .content-desc .entry:before {
    content: "";
    width: 100vw;
    height: 100%;
    background-image: url(../images/home-car.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45vw;
    top: 0;
    left: -50vw;
    position: absolute;
    display: inline-block;
    z-index: 1;
  }
  .landing-slider .slider-fade .slick-slide {
    height: inherit;
    min-height: inherit;
    background-position: top;
    background-size: contain;
    position: relative;
  }
  .landing-slider .slider-fade .slider_img {
    min-height: inherit;
  }
  .landing-slider .slider-fade .slider_img .slider-wrap {
    position: initial;
    top: initial;
    -webkit-transform: inherit;
    transform: inherit;
  }
  .slider-fade .slider_img .slider-desc .btn {
    width: 100%;
    margin: 0 0 15px;
  }
  .landing-slider .slider-fade .slick-slide .slider-desc {
    top: inherit;
    bottom: 0;
    padding: 20px;
    height: inherit;
    max-height: inherit;
  }
  .landing-slider .slider-fade .slick-slide .slider-desc h3 {
    font-size: 18px;
    line-height: 1.2;
    margin: 13px 0 10px;
  }
  .slick-next, .slick-prev {
    display: none !important;
  }
  .landing-slider .slider-fade .slick-slide .slider-desc p {
    font-size: 14px;
    line-height: 1.5;
  }
  .section-entry h2 {
    font-size: 32px;
  }
  .page-template-template-media-library {
    .overlay-articles article {
      padding: 0 15px;
    }
  }
  .overlay-articles article {
    margin-bottom: 30px;
    padding: 0 30px;
  }
}
@media (max-width: 720px) {
  .wpsl-search {
    padding: 15px;
  }
  #wpsl-search-wrap .wpsl-dropdown {
    width: 100%;
  }
  #wpsl-search-btn, #wpsl-search-wrap div {
    margin-right: 0px;
    width: 100%;
  }
}
@media (max-width: 570px) {
  .dealer_loc #wpsl-search-wrap .wpsl-input {
    margin-right: 0;
  }
  #wpsl-search-wrap .wpsl-dropdown {
    width: 100%!important;
  }
  #wpsl-search-wrap div label {
    margin-right: 0;
  }
  #wpsl-search-btn, #wpsl-search-wrap div {
    margin-right: 0;
    width: 100%;
  }
}