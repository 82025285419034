.page-child, .search, .category, .single, .page, .blog, .page-template, .archive {
  .top-header {
    position: relative; //@include dropshadow();
    .brand {
      background: url(../images/toyota-logo-v2.svg) 0 0 no-repeat;
      background-size: 330px 71px;
    }
  }
  .navbar-nav>li a {
    color: $red;
  }
}
.home {
  .header {
    position: relative;
    @include dropshadow();
    background-color: #212529;
    width: 100%;
    .brand {
      //background: url(../images/logo-drk_v2.svg) 0 0 no-repeat;
    }
    .main-nav {
     // display: none;
    }
  }
}
//search form-group
.search_form {
  padding: 45px 0 0px;
  background: #f5f5f5;
  border-bottom: 1px solid #eee;
  p {
    margin: 0 0 12px;
    font-family: "ToyotaType-Semibold";
  }
  form {
    position: relative;
    .form-control {
      padding: 1rem .75rem;
      font-size: 24px;
      text-transform: uppercase;
      background: #fff;
      font-family: "ToyotaType-Semibold";
      border-radius: 0;
      border: none;
    }
    .comment-form p, .form-group, .search-form label {
      margin: 0;
    }
    .form-group {
      position: relative;
    }
  }
  .button {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -21px;
  }
}
.top-header {
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px;
  z-index: 99;
  background-color: $drk;
  .brand {
    background: url(../images/logo.svg) 0 0 no-repeat;
    background-size: 325px 69px;
    width: 325px;
    height: 69px;
    position: relative;
    display: block;
    text-indent: -9999em;
    float: left;
    top: 0;
  }
}
.main-nav {
  float: right;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
  border: none;
}
.navbar-nav>li.menu-sign-in {
  padding-bottom: 0px;
}

.navbar-nav>li {
	position: relative;
}

.navbar-nav>li::after {
	content: "";
	border-right: 1px solid #4c4c4c;
	position: absolute;
	top: 20px;
	bottom: 20px;
	right: 0;
}
.navbar-nav>li:last-child {
	&::after {
		display: none;
	}
}

.navbar-nav>li.menu-sign-in.active a {
  color: #fff;
  &:hover {
    color: $red;
  }
}
.navbar-nav>li.active>a {
  font-weight: 600;
}
.navbar-nav>li.menu-microcat-login {
  a {
    background-color: $red;
    padding: 12px 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 13px;
    &:hover {}
    &:after {
      display: none;
    }
  }
}
.navbar-nav>li {
  float: none;
  display: inline-block;
  a {
    color: #fff;
    margin: 0 10px;
    padding: 23px 10px 24px;
    position: relative;
    display: inline-block;
		@include quick_easing();
    &:hover {
      color: #fff;
      text-decoration: none;
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 7px;
      right: 7px;
      background: #fff;
      height: 4px;
      opacity: 0;
      @include quick_easing();
    }
  }
  &.menu-item-has-children {
		position: relative;
		
    a {
      &:after {
        display: none;
      }
    }
  }
	
	&.menu-item-has-children:before {
				content: "";
				position: absolute;
				right: 0;
				top:50%;
			  margin-top: -2.5px;
				width: 0; 
				height: 0; 
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid $red;
				font-size: 0;
				line-height: 0;
				@include quick_easing();
		}
	
  &.active {
    a {
      &:after {
        opacity: 1;
      }
    }
  }
	&:hover {
		&.menu-item-has-children:before {
			border-top: 5px solid white;
		}
	}
	
}
@media (max-width: 1200px) {
  .sign-nav {
    display: none;
  }
  .mobilemenu .sign-nav {
    display: block;
  }
  .sign-nav {
    float: none;
    margin-top: 0px;
  }
  .navbar-nav>li {
    margin: 0 5px;
  }
}
.navbar-nav li a {
  font-family: "ToyotaType-Semibold";
}
.navbar-nav>li.active a {
  color: $red;
}
.nav>li>a:focus, .nav>li>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  
}
.navbar-nav>li, .navbar-nav>li>.sub-menu li {
  position: relative;
  display: inline-block;
}
/*
.navbar-nav > li:after {
    content: "";
    position: absolute;
    bottom: 9px;
    left: 9px;
    right: 9px;
    display: block;
    height: 2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #ddd;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
*/

.navbar-nav>li.active:after {
  background:#fff;
}
.navbar-nav>li.active a {
  color: #fff;
}

.navbar-nav>li.active {
&.menu-item-has-children:before {
			border-top: 5px solid white;
		}
}

/*
.navbar-nav > li .sub-menu li:after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    display: block;
    height: 2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #ddd;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
*/

.navbar-nav>li>.sub-menu li:hover:after, .navbar-nav>li:hover:after {
  @include easing;
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.navbar-nav>li>.sub-menu li.active:after, .navbar-nav>li.active:after {
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.navbar-nav>li>.sub-menu li.active a {
  color: $red;
  font-family: "ToyotaType-Semibold"; //background: #f5f5f5;
}
.navbar-nav>li>.sub-menu {
  opacity: 0;
  z-index: -10;
  visibility: hidden;
  .sub-menu {
    margin: 5px 0 0;
    padding: 3px 0 10px;
    background: $red;
    li.menu-collision-pros-magazine {
      a {
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        &:hover {
          background: none;
          color: #fff;
        }
        &:before {
          display: none;
        }
      }
    }
    li {
      a {
        color: #fff;
        &:hover {
          background: none;
          color: #fff;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #fff;
          margin: 0 7px 2px 0;
        }
      }
    }
  }
}
.navbar-nav>li>.sub-menu {
  margin-top: 0;
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
  float: left;
  width: 235px;
  position: absolute;
  top: 70px;
  padding-top: 5px;
  background: url('../images/drop_down_arrow.png') center top no-repeat;
}
.navbar-nav>li:hover>.sub-menu {
  z-index: 10;
  margin-top: 0;
  top: 69px;
  opacity: 1.0;
  visibility: visible;
}
.navbar-nav>li>.sub-menu {
  border: 1px solid #dcdcdc;
  list-style-type: none;
  background: #ffffff;
  text-align: left;
  padding: 10px 0 10px;
  right: 0px;
  margin-left: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
  li {
    list-style: none;
    display: block;
    a {
      font-family: "ToyotaType-Regular";
      color: $red;
      padding: 9px 10px 11px;
      display: block;
      line-height: 1.2;
      @include easing;
      &:hover {
        text-decoration: none;
        color: $red;
        background: #f5f5f5;
      }
    }
  }
}
.navbar-nav>li>.sub-menu:after {
  top: 0;
  right: 60px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 0px;
  margin-left: 0;
  border: 8px solid black;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -1px 1px 0px 0 rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: -1px 1px 0px 0 rgba(0, 0, 0, 0.17);
  z-index: 0;
}
.hamburger-menu {
  display: none;
}
.mobilemenu {
  display: none;
}
@media (max-width: 1024px) {
  .main-nav {
    display: none;
  }
  .home {
    .hamburger-menu {
      //display: none;
    }
  }
  .hamburger-menu {
    display: block;
  }
  .inner-nav {
    padding-top: 80px;
  }
  .accordion-menu {
    text-align: left;
    .menu-collision, .menu-mechanical {
      >a {
        text-transform: uppercase;
        border-bottom: 1px solid $red;
      }
    }
    .menu-item-has-children>a {
      //text-transform: uppercase;
    }
    .menu-item-has-children {
      .active {
        //font-weight: bold;
      }
    }
    li {
      display: block;
      width: 100%;
      margin-bottom: 10px;
			font-size: 18px;
      ul {
        display: block;
      }
      .sub-menu {
        margin: 10px 0 0;
        padding: 0 0 0 15px;
      }
      .menu-news-tools {
        .sub-menu {
          margin: 0;
          padding: 20px 0;
          li.menu-collision-pros-magazine {
            a {
              text-transform: uppercase;
              border-bottom: 1px solid $red;
            }
            &:before {
              display: none;
            }
          }
          li {
            &:before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 8px;
              background: $red;
              margin: 0 7px 2px 0;
            }
          }
        }
      }
    }
    .menu-microcat-login {
      margin-top: 10px;
      a {
        background: $red;
        padding: 8px 20px;
        color: #fff;
        text-align: center;
      }
    }
  }
  .menu-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: hsla(0, 0%, 0%, 0.26);
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    -webkit-transition: all 1s cubic-bezier(.23, 1, .32, 1);
    -moz-transition: all 1s cubic-bezier(.23, 1, .32, 1);
    -o-transition: all 1s cubic-bezier(.23, 1, .32, 1);
    transition: all 1s cubic-bezier(.23, 1, .32, 1);
  }
  .menu-overlay.darken {
    opacity: 1;
    visibility: visible;
  }
  .mobilemenu {
    .sign-nav {
      .navbar-nav {
        margin: 0;
      }
    }
  } // Mobile Menu
  .mobilemenu {
    display: none;
  }
  $bar-width: 26px;
  $bar-height: 1px;
  $bar-spacing: 8px;
  .hamburger-menu {
    position: absolute;
    top: 26px;
    right: 30px;
    margin: auto;
    z-index: 99999;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
  }
  .bar, .bar:after, .bar:before {
    width: $bar-width;
    height: $bar-height;
  }
  .bar {
    position: relative;
    transform: translateY($bar-spacing);
    background: #fff;
    transition: all 0ms 300ms;
    &.animate {
      background: rgba(255, 255, 255, 0);
    }
  }
  .bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: #fff;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .bar:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: #fff;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .bar.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
  }
  .menu_open {
    .mobilemenu {
      right: 0;
      box-shadow: -8px 0 25px -4px rgba(10, 18, 26, .36);
    }
    .top-header {
      z-index: 99999;
    }
  }
  .mobilemenu {
    display: block;
    top: 0;
    position: fixed;
    right: -50%;
    width: 50%;
    height: 100vh;
    background: white;
    z-index: 9999;
    padding: 30px;
    text-align: center;
    @include cubic();
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: absolute; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    .brand {
      display: block;
      float: none;
      width: 100px;
      height: 100px;
      background-size: contain;
      margin: 0 auto 30px;
    }
    #closeMenu {
      position: absolute;
      background: #002857;
      color: white;
      width: 50px;
      height: 50px;
      line-height: 50px;
      left: -50px;
      top: 0;
      font-size: 36px;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      -moz-transition: opacity .3s ease-in-out;
      -webkit-transition: opacity .3s ease-in-out;
      transition-delay: .2s;
    }
    &.active {
      right: 0;
      -webkit-box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
      -moz-box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
      box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
      #closeMenu {
        opacity: 1;
      }
    }
		
		.nav>li>a:focus, .nav>li>a:hover {
			color: $red;
		}
		
		.nav>li>a {
			border-bottom: 1px solid white;
		}
		
		.nav>li.active {
			a {
				border-bottom: 1px solid $red;
			}
			
		}
		
  }
}
@media (max-width: 668px) {
  .main {
    padding-top: 41px;
  }
  .archive .top-header, .blog .top-header, .category .top-header, .page-child .top-header, .page-template .top-header, .page .top-header, .search .top-header, .single .top-header {
    position: fixed;
  }
  .archive .top-header .brand, .blog .top-header .brand, .category .top-header .brand, .page-child .top-header .brand, .page-template .top-header .brand, .page .top-header .brand, .search .top-header .brand, .single .top-header .brand {
    background-size: 192px 41px;
  }
  .top-header .brand {
    width: 192px;
    height: 41px;
    background-size: 192px 41px;
    display: block;
    float: left;
    position: relative;
    top: 0;
  }
  .inner-nav {
    padding-top: 30px;
  }
  .hamburger-menu {
    top: 10px;
    right: 20px;
    position: fixed;
  }
  .mobilemenu {
    right: -100%;
    width: 100%;
  }
  .search_form {
    padding: 30px 0 0;
  }
  .search_form form .form-control {
    font-size: 16px;
    padding: .75rem;
  }
  .search_form .button {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    padding: 4px 20px;
  }
}