.pdf-customizer {
	margin-bottom: 60px;
	.section-title {
		
		p {
			max-width: 665px;
		}
	}
	.thumbnail {
		margin-bottom: 30px;
	}
	.part-type {
		a {
			display: block;
			padding: 5px;
			border: 2px solid transparent;
			&.active {
				border: 2px solid $red;
			}
			span {
				display: block;
				font-family: "ToyotaType-Bold";
				text-transform: uppercase;
				font-size: 12px;
				text-align: center;
				padding: 10px 0 0;
			}
		}
	}
	.btn-next {
		opacity: 0;
		margin-top: 20px;
		position: relative;
	}
	.btn-back {
		//opacity: 0;
		margin-bottom: 30px;
		position: relative;
	}
}
.row-form {
	display: none;
	overflow: hidden;
	&._activate {
		display: flex;
	}
}
.toyota-flyer-logo {
	max-width: 350px;
	padding-bottom: 30px;
}
.pdf-card {
	&.card {
		border: none;
		border-radius: 0rem;
		padding-top: 30px;
		display: block;
	}
	.pdf-bg {
		position: relative;
		.promo-box {
			position: absolute;
			top: 15px;
			right: 15px;
			background: $red;
			padding: 30px 15px;
			max-width: 160px;
			h3 {
				color: #fff;
				font-family: "ToyotaType-Bold";
				font-size: 1.6rem;
				line-height: 1;
				text-transform: uppercase;
			}
			.promo-date {
				color: #fff;
				font-family: "ToyotaType-Bold";
				font-size: .75rem;
				text-transform: uppercase;
				display: block;
				margin: 3px 0 0;
			}
		}
	}
	small {
		font-size: 9px;
		line-height: 1.2;
	}
}
.promo-details {
	margin: 20px 0 20px;
	overflow: hidden;
	.left-col {
		float: left;
		width: 70%;
		position: relative;
		padding-right: 10px;
		&::before {
			content: "";
			border-left: 1px solid #ddd;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 10px;
		}
		.promo-details-desc {
			padding-right: 10px;
			p {
				font-size: .8rem;
				line-height: 1.2;
				margin: 0;
			}
		}
		.promo-details-logo {
			max-width: 150px;
			margin: 20px 0 0;
            img#previewimg {
            max-height: 80px;
            width: auto;
                }
		}
		.dealer-details {
			margin: 10px 0 0;
			span {
				font-size: .8rem;
				line-height: 1;
				margin: 0px;
				font-family: "ToyotaType-Bold";
			}
		}
		.dealer-url {
			color: $red;
			display: block;
		}
	}
	.right-col {
		float: left;
		width: 30%;
		.dealer-addy {
			h4 {
				color: $red;
				font-family: "ToyotaType-Bold";
				font-size: .85rem;
				line-height: 1;
				text-transform: uppercase;
				margin: 0 0 2px;
			}
			p {
				font-size: .8rem;
				line-height: 1.2;
				margin: 0 0 2px;
			}
		}
	}
}
.parts-manager {
	margin: 10px 0 0;
	p {
		font-size: .8rem;
		line-height: 1.2;
		margin: 0 0 2px;
	}
}
#dealer_logo .custom-file-control:lang(en)::after {
	content: "Dealer Logo...";
}
#dealer_logo .custom-file-control:lang(en)::before {
	content: "Upload";
}
/*when a value is selected, this class removes the content */

.custom-file-control.selected:lang(en)::after {
	content: "" !important;
}
.custom-file {
	overflow: hidden;
	.custom-file-control:before {
		position: absolute;
		top: -1px;
		right: -1px;
		bottom: -1px;
		z-index: 6;
		display: block;
		height: 2.5rem;
		padding: .5rem 1rem;
		line-height: 1.2;
		color: #495057;
		background-color: #e9ecef;
		border: 1px solid rgba(0, 0, 0, .15);
		border-radius: 0 .25rem .25rem 0;
	}
}
.custom-file-control {
	white-space: nowrap;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	height: 2.5rem;
	padding: .5rem 1rem;
	line-height: 1.2;
	color: #495057;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: .25rem;
}
.pdf-form-fields select.form-control:not([size]):not([multiple]) {
	height: 45px;
	padding: 0  .75rem;
}
.l-wrap {
	max-width: 600px;
	margin: 0 auto;
	padding: 30px 0;
}
/* Parsley CSS */

.parsley-success {
	color: #468847;
	background-color: #DFF0D8;
	border: 1px solid #D6E9C6;
}
.parsley-error {
	color: #B94A48;
	background-color: #F2DEDE;
	border: 1px solid #EED3D7;
}
.parsley-errors-list {
	margin: 2px 0 3px;
	padding: 0;
	list-style-type: none;
	font-size: .8em;
	line-height: 1.1;
	opacity: 0;
	transition: all .3s ease-in;
	-o-transition: all .3s ease-in;
	-moz-transition: all .3s ease-in;
	-webkit-transition: all .3s ease-in;
}
.parsley-errors-list.filled {
	opacity: 1;
}